import React, { forwardRef, useState, useEffect, useRef } from 'react';

const TemplatePreview = forwardRef(({ school, formData }, ref) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const containerRef = useRef(null);
  const prevSchoolId = useRef(school?.id);

  // 학교가 변경될 때마다 로딩 상태 초기화
  useEffect(() => {
    if (prevSchoolId.current !== school?.id) {
      setImageLoading(true);
      prevSchoolId.current = school?.id;
    }
  }, [school?.id]);

  // 화면 크기에 따른 스케일 계산
  useEffect(() => {
    const calculateScale = () => {
      if (!containerRef.current) return;
      
      const viewportWidth = Math.min(window.innerWidth - 32, 720);
      const templateWidthPx = school.previewWidth * 3.7795275591;
      const newScale = viewportWidth / templateWidthPx;
      setScale(Math.min(Math.max(newScale, 0.5), 1));
    };

    calculateScale();
    window.addEventListener('resize', calculateScale);
    return () => window.removeEventListener('resize', calculateScale);
  }, [school.previewWidth]);

  if (!school) return null;

  const containerStyle = {
    position: 'relative',
    width: `${school.previewWidth}mm`,
    height: `${school.previewHeight}mm`,
    margin: '0 auto',
    backgroundColor: 'white',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    transform: `scale(${scale})`,
    transformOrigin: 'top center'
  };

  // 입력 필드 스타일에도 스케일 적용
  const getFieldStyle = (field) => {
    const topKey = `preview_${field}Top`;
    return {
      position: 'absolute',
      top: `${school[topKey]}px`,
      left: `${school.preview_left}px`,
      fontSize: `${16 / scale}px` // 폰트 크기도 스케일에 맞게 조정
    };
  };

  return (
    <div ref={containerRef}>
      <div ref={ref} style={containerStyle}>
        {imageLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
          </div>
        )}
        
        {school && (
          <img
            key={school.id} // 학교 변경 시 이미지 강제 리로드
            src={school.templateUrl}
            alt={`${school.name} 템플릿`}
            onLoad={() => setImageLoading(false)}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: imageLoading ? 0 : 1,
              transition: 'opacity 0.2s ease'
            }}
          />
        )}

        {/* 기존 입력 필드 렌더링 코드 */}
        {school.hasFields && !imageLoading && (
          <>
            {Object.entries(formData).map(([field, value]) => {
              if (!value || school[`preview_${field}Top`] === undefined) return null;
              return (
                <div key={field} style={getFieldStyle(field)}>
                  {field === 'grade' ? `${value}학년` : value}
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
});

export default TemplatePreview;