import React, { useState } from 'react';
import SchoolSelector from '../components/SchoolSelector';
import UserForm from '../components/UserForm';
import TemplatePreview from '../components/TemplatePreview';
import ActionButtons from '../components/ActionButtons';
import { db } from '../services/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Analytics } from '../utils/analytics';

function Home() {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [formData, setFormData] = useState({});

  const handleSchoolSelect = async (schoolId) => {
    if (!schoolId) {
      setSelectedSchool(null);
      return;
    }

    try {
      const docRef = doc(db, 'universities', schoolId);
      const docSnap = await getDoc(docRef);
      
      if (!docSnap.exists()) {
        console.error('School not found');
        setSelectedSchool(null);
        return;
      }

      const schoolData = {
        id: docSnap.id,
        ...docSnap.data()
      };
      setSelectedSchool(schoolData);
      
      // 학교 선택 이벤트 로깅
      Analytics.schoolSelected(schoolData.name, schoolData.id);
    } catch (error) {
      console.error('Error fetching school data:', error);
      setSelectedSchool(null);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 px-2 sm:px-4">
      <div className="max-w-7xl mx-auto py-8 sm:py-12">
        <div className="text-center mb-8 sm:mb-12">
          <h1 className="text-3xl font-bold text-gray-900">대학별 레포트 표지</h1>
          <p className="mt-2 text-sm text-gray-600">
            레포트표지를 간편하게 [ 출력 또는 다운로드(PDF) ] 할 수 있습니다.
          </p>
        </div>

        <div className="max-w-sm mx-auto mb-6 sm:mb-8">
          <SchoolSelector 
            value={selectedSchool?.id || ''} 
            onChange={handleSchoolSelect} 
          />
        </div>

        {selectedSchool && (
          <>
            <div className="max-w-md mx-auto mb-8 sm:mb-12 flex justify-center gap-4">
              <ActionButtons school={selectedSchool} formData={formData} />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-[auto,auto] lg:gap-6 lg:justify-center">
              <div className="lg:order-1 flex justify-center">
                <TemplatePreview school={selectedSchool} formData={formData} />
              </div>

              {selectedSchool.hasFields && (
                <div className="lg:order-2 w-full lg:w-[320px]">
                  <div className="bg-white rounded-lg shadow-sm border border-gray-200 px-4 py-6 sm:px-6">
                    <UserForm 
                      onSubmit={(data) => setFormData(data)} 
                      schoolName={selectedSchool.name}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Home;