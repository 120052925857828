import React, { useState } from 'react';
import { Analytics } from '../../utils/analytics';

function UserForm({ onSubmit, schoolName }) {
  const [formData, setFormData] = useState({
    subject: '',
    professor: '',
    department: '',
    grade: '',
    studentId: '',
    name: '',
    submitDate: new Date().toISOString().split('T')[0],
  });

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value.trim()) {
      Analytics.formFieldUsed(name, schoolName);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {
      ...formData,
      [name]: value
    };
    setFormData(newFormData);
    onSubmit(newFormData);
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium text-gray-900">정보 입력</h2>
        <p className="mt-1 text-sm text-gray-600">
          모든 항목은 <span className="font-semibold">선택사항</span>입니다. 입력하지 않고 PDF 다운로드나 인쇄도 가능합니다.
        </p>
      </div>
      
      <div className="space-y-4">
        {/* 과목명 */}
        <div>
          <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
            과목명
          </label>
          <input
            type="text"
            name="subject"
            id="subject"
            value={formData.subject}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* 담당교수 */}
        <div>
          <label htmlFor="professor" className="block text-sm font-medium text-gray-700">
            담당교수
          </label>
          <input
            type="text"
            name="professor"
            id="professor"
            value={formData.professor}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* 학과 */}
        <div>
          <label htmlFor="department" className="block text-sm font-medium text-gray-700">
            학과
          </label>
          <input
            type="text"
            name="department"
            id="department"
            value={formData.department}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* 학년 */}
        <div>
          <label htmlFor="grade" className="block text-sm font-medium text-gray-700">
            학년
          </label>
          <select
            name="grade"
            id="grade"
            value={formData.grade}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">학년 선택</option>
            <option value="1">1학년</option>
            <option value="2">2학년</option>
            <option value="3">3학년</option>
            <option value="4">4학년</option>
          </select>
        </div>

        {/* 학번 */}
        <div>
          <label htmlFor="studentId" className="block text-sm font-medium text-gray-700">
            학번
          </label>
          <input
            type="text"
            name="studentId"
            id="studentId"
            value={formData.studentId}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* 이름 */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            이름
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* 제출일 */}
        <div>
          <label htmlFor="submitDate" className="block text-sm font-medium text-gray-700">
            제출일
          </label>
          <input
            type="date"
            name="submitDate"
            id="submitDate"
            value={formData.submitDate}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>
    </div>
  );
}

export default UserForm;